/** @jsx jsx */

import { Fragment, useEffect, useState } from 'react'
import { jsx } from 'theme-ui'

import Halo from '../components/halo'
import { faces, formatFace } from '../utilities'

const Cycle = () => {
  const [currentCode, setCurrentCode] = useState(faces[0].code)

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentCode(faces[Math.floor(Math.random() * faces.length)].code)
    }, 100)

    return () => clearInterval(interval)
  }, [])

  return (
    <Fragment>
      <Halo title="Cycle" />
      <style>{`body { margin: 0 }`}</style>

      <main
        sx={{
          bg: `black`,
          display: `grid`,
          height: `100vh`,
          placeContent: `center`,
        }}
      >
        <pre
          sx={{
            bg: `characterPrimaryBackground`,
            color: `characterPrimaryText`,
            display: `grid`,
            fontFamily: `monospace`,
            fontSize: `20px`,
            fontWeight: `500`,
            lineHeight: `1.2`,
            my: 0,
            placeContent: `center`,
            size: 130,
          }}
        >
          {formatFace(currentCode)}
        </pre>
      </main>
    </Fragment>
  )
}

export default Cycle
